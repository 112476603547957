"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));
var _slice = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/slice"));
var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));
var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-userbox"
  }, [_vm.isSelf ? _c('span', {
    staticClass: "kiwi-userbox-selfprofile"
  }, [_vm._v(" " + _vm._s(_vm.$t('user_you')) + " ")]) : _vm._e(), _c('div', {
    staticClass: "kiwi-userbox-header"
  }, [_c('div', {
    staticClass: "kiwi-userbox-avatar"
  }, [_c('UserAvatar', {
    attrs: {
      "user": _vm.user,
      "network": _vm.network,
      "size": "large"
    }
  })], 1), _c('div', {
    staticClass: "kiwi-userbox-userinfo"
  }, [_c('span', {
    staticClass: "kiwi-userbox-nick",
    style: {
      'color': _vm.user.getColour()
    }
  }, [_vm._v(_vm._s(_vm.user.nick))]), _vm.userMode ? _c('span', {
    staticClass: "kiwi-userbox-modestring"
  }, [_vm._v("+" + _vm._s(_vm.userMode))]) : _vm._e(), _c('span', {
    staticClass: "kiwi-userbox-usermask"
  }, [_vm._v(_vm._s(_vm.user.username) + "@" + _vm._s(_vm.user.host))])])]), _vm.pluginUiInfoElements.length ? _vm._l(_vm.pluginUiInfoElements, function (plugin) {
    return _c(plugin.component, _vm._b({
      key: plugin.id,
      tag: "component",
      attrs: {
        "plugin-props": {
          user: _vm.user,
          userbox: _vm.self
        },
        "network": _vm.network,
        "buffer": _vm.buffer,
        "user": _vm.user,
        "sidebar-state": _vm.sidebarState
      }
    }, 'component', plugin.props, false));
  }) : _vm.realname ? _c('div', {
    staticClass: "kiwi-userbox-basicinfo"
  }, [_c('span', {
    staticClass: "kiwi-userbox-basicinfo-title"
  }, [_vm._v(_vm._s(_vm.$t('whois_realname')) + ":")]), _c('span', {
    staticClass: "kiwi-userbox-basicinfo-data",
    domProps: {
      "innerHTML": _vm._s(_vm.formattedRealname)
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "kiwi-userbox-actions"
  }, [!_vm.isSelf && !_vm.buffer.isQuery() ? _c('a', {
    staticClass: "kiwi-userbox-action",
    on: {
      "click": _vm.openQuery
    }
  }, [_c('i', {
    staticClass: "fa fa-comment-o",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('send_a_message')) + " ")]) : _vm._e(), !_vm.whoisRequested ? _c('a', {
    staticClass: "kiwi-userbox-action",
    on: {
      "click": _vm.updateWhoisData
    }
  }, [_c('i', {
    staticClass: "fa fa-question-circle",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('more_information')) + " ")]) : _vm._e(), _c('div', {
    staticClass: "kiwi-userbox-actions kiwi-userbox-plugin-actions"
  }, _vm._l(_vm.pluginUiButtonElements, function (plugin) {
    return _c(plugin.component, _vm._b({
      key: plugin.id,
      tag: "component",
      attrs: {
        "plugin-props": {
          user: _vm.user,
          userbox: _vm.self
        },
        "network": _vm.network,
        "buffer": _vm.buffer,
        "user": _vm.user,
        "sidebar-state": _vm.sidebarState
      }
    }, 'component', plugin.props, false));
  }), 1)]), !_vm.isSelf ? _c('form', {
    staticClass: "u-form kiwi-userbox-ignoreuser"
  }, [_c('label', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user.ignore,
      expression: "user.ignore"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.user.ignore) ? _vm._i(_vm.user.ignore, null) > -1 : _vm.user.ignore
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.user.ignore,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.user, "ignore", (0, _concat.default)($$a).call($$a, [$$v]));
          } else {
            var _context;
            $$i > -1 && _vm.$set(_vm.user, "ignore", (0, _concat.default)(_context = (0, _slice.default)($$a).call($$a, 0, $$i)).call(_context, (0, _slice.default)($$a).call($$a, $$i + 1)));
          }
        } else {
          _vm.$set(_vm.user, "ignore", $$c);
        }
      }
    }
  }), _c('span', [_vm._v(" " + _vm._s(_vm.$t('ignore_user')) + " ")])])]) : _vm._e(), _vm.whoisRequested ? _c('div', {
    staticClass: "kiwi-userbox-whois",
    class: [_vm.whoisLoading ? 'kiwi-userbox-whois--loading' : '']
  }, [_vm.whoisLoading ? [_c('i', {
    staticClass: "fa fa-spin fa-spinner",
    attrs: {
      "aria-hidden": "true"
    }
  })] : _vm.pluginUiWhoisElements.length ? _vm._l(_vm.pluginUiWhoisElements, function (plugin) {
    return _c(plugin.component, _vm._b({
      key: plugin.id,
      tag: "component",
      attrs: {
        "plugin-props": {
          user: _vm.user,
          userbox: _vm.self
        },
        "network": _vm.network,
        "buffer": _vm.buffer,
        "user": _vm.user,
        "sidebar-state": _vm.sidebarState
      }
    }, 'component', plugin.props, false));
  }) : [_c('span', {
    staticClass: "kiwi-userbox-whois-line"
  }, [_vm._v(" " + _vm._s(_vm.user.away ? _vm.$t('whois_status') + ': ' + _vm.user.away : _vm.$t('whois_status_available')) + " ")]), _vm.user.account ? _c('span', {
    staticClass: "kiwi-userbox-whois-line"
  }, [_vm._v(" " + _vm._s(_vm.$t('user_account', {
    user: _vm.user.account
  })) + " ")]) : _vm._e(), _c('span', {
    staticClass: "kiwi-userbox-whois-line"
  }, [_vm._v(" " + _vm._s(_vm.$t('user_realname', {
    realname: _vm.user.realname
  })) + " ")]), _vm.user.whois.bot ? _c('span', {
    staticClass: "kiwi-userbox-whois-line"
  }, [_vm._v(" " + _vm._s(_vm.$t('user_bot')) + " ")]) : _vm._e(), _vm.user.whois.helpop ? _c('span', {
    staticClass: "kiwi-userbox-whois-line"
  }, [_vm._v(" " + _vm._s(_vm.$t('user_help')) + " ")]) : _vm._e(), _vm.user.whois.operator ? _c('span', {
    staticClass: "kiwi-userbox-whois-line"
  }, [_vm._v(" " + _vm._s(_vm.$t('user_op')) + " ")]) : _vm._e(), _vm.user.whois.server ? _c('span', {
    staticClass: "kiwi-userbox-whois-line"
  }, [_vm._v(" " + _vm._s(_vm.$t('user_server', {
    server: _vm.user.whois.server,
    info: _vm.user.whois.server_info ? "(".concat(_vm.user.whois.server_info, ")") : ''
  })) + " ")]) : _vm._e(), _vm.user.whois.secure ? _c('span', {
    staticClass: "kiwi-userbox-whois-line"
  }, [_vm._v(" " + _vm._s(_vm.$t('user_secure')) + " ")]) : _vm._e(), _vm.user.whois.channels ? _c('span', {
    staticClass: "kiwi-userbox-whois-line",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('user_channels', {
        channels: _vm.userChannels
      }))
    },
    on: {
      "click": function click($event) {
        return _vm.onChannelsClick($event);
      }
    }
  }) : _vm._e()]], 2) : _vm._e(), _vm.buffer.isChannel() && _vm.areWeAnOp && !_vm.isSelf ? _c('div', {
    staticClass: "kiwi-userbox-opactions"
  }, [_c('form', {
    staticClass: "u-form",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
      }
    }
  }, [_vm.isUserOnBuffer ? _c('label', [_vm._v(" " + _vm._s(_vm.$t('user_access')) + " "), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.userMode,
      expression: "userMode"
    }],
    on: {
      "change": function change($event) {
        var _context2;
        var $$selectedVal = (0, _map.default)(_context2 = (0, _filter.default)(Array.prototype).call($event.target.options, function (o) {
          return o.selected;
        })).call(_context2, function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.userMode = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, [_vm._l(_vm.availableChannelModes, function (mode) {
    return _c('option', {
      key: mode.mode,
      domProps: {
        "value": mode.mode
      }
    }, [_vm._v(" " + _vm._s(mode.description) + " ")]);
  }), _c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v(_vm._s(_vm.$t('user_normal')))])], 2)]) : _vm._e(), _vm.isUserOnBuffer ? _c('label', [_c('button', {
    staticClass: "u-button u-button-secondary kiwi-userbox-opaction-kick kiwi-userbox-opaction",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.buffer.kickUser(_vm.user);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-sign-out",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('user_kick')) + " ")])]) : _vm._e(), _c('label', [_c('button', {
    staticClass: "u-button u-button-secondary kiwi-userbox-opaction-ban kiwi-userbox-opaction",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.buffer.banUser(_vm.user);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-ban",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('user_ban')) + " ")])]), _vm.isUserOnBuffer ? _c('label', [_c('button', {
    staticClass: "u-button u-button-secondary kiwi-userbox-opaction-kickban kiwi-userbox-opaction",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.buffer.banKickUser(_vm.user);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-exclamation-triangle",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('user_kickban')) + " ")])]) : _vm._e()])]) : _vm._e(), _vm.buffer.isQuery() && _vm.invitableBuffers.length > 0 ? _c('div', {
    staticClass: "kiwi-userbox-query-invite kiwi-userbox-actions u-form"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.inviteChan,
      expression: "inviteChan"
    }],
    staticClass: "u-input",
    on: {
      "change": function change($event) {
        var _context3;
        var $$selectedVal = (0, _map.default)(_context3 = (0, _filter.default)(Array.prototype).call($event.target.options, function (o) {
          return o.selected;
        })).call(_context3, function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.inviteChan = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v(_vm._s(_vm.$t('select_channel')))]), _vm._l(_vm.invitableBuffers, function (chan) {
    return _c('option', {
      key: 'inviteChan-' + chan,
      domProps: {
        "value": chan
      }
    }, [_vm._v(_vm._s(chan))]);
  })], 2), _c('a', {
    staticClass: "u-button u-button-secondary",
    on: {
      "click": function click($event) {
        return _vm.inviteUser();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invite_user')) + " ")])]) : _vm._e()], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;